import React from "react"
import Helmet from "react-helmet"
import Header from "../components/headers/header_contact"
import Footer from "../components/footers/footer"
import FrmContact from "../components/forms/contact"


import { EnvelopeIcon, PhoneIcon, AtSymbolIcon } from '@heroicons/react/24/outline'

export default function Contact() {
    return (
      <div className="bg-white">
        <Helmet>
          <title>Contact - Fissurometre.fr</title>
          <link rel="canonical" href="https://www.fissurometre.fr/contact/" />
        </Helmet>
        <header>
          <Header />
        </header>
        <main>
            <div className="relative bg-white">
              <div className="relative max-w-7xl mx-auto py-8 px-4">
                <h1>
                  <span className="mt-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Demande de renseignements ou devis
                  </span>
                </h1>
                <p className="mt-8 mb-8 text-xl text-gray-500 leading-8">
                Votre maison ou appartement est fissuré. Trouvez une solution avec l'équipe Fissuration. 
Causes des fissures, évaluation des risques, solutions travaux, aide sur le plan des recours....
                </p>
                <p className="mt-8 mb-8 text-xl text-gray-500 leading-8">
                Nous mettons tout en œuvre pour vous aider à résoudre votre problématique de fissures.
                </p>
              </div>
            </div>




            <div className="relative bg-white">
              <div className="absolute inset-0">
                <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
              </div>
              <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
                

                <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
                  <div className="max-w-lg mx-auto">
                    <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Compétences et proximité</h1>
                    <p className="mt-3 text-lg leading-6 text-gray-500">
                      Expertises indépendante des fissures, études de sol G5, travaux de reprise de fondations, réparation des fissures...
                    </p>
                    <p className="mt-3 text-lg leading-6 text-gray-500">
                    Nos équipes internes et partenaires interviennent dans différents types de contexte fissuration :
        Avis avant achat immobilier, garantie dommage-ouvrage et décennale, garantie des catastrophes naturelles sécheresse, litige amiable ou judiciaire
                    </p>
                    <p className="mt-3 text-lg leading-6 text-gray-500">
                    Intervention partout en France
        Obtenez l’aide dans les plus brefs délais en remplissant le formulaire.
                    </p>
                    <dl className="mt-8 text-base text-gray-500">
                      <div>
                        <dt className="sr-only">En cas d'urgence, nous contacter au :</dt>
                      </div>
                      <div className="mt-6">
                        <dt className="sr-only">Téléphone</dt>
                        <dd className="flex">
                          <PhoneIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                          <span className="ml-3">06 63 86 19 84</span>
                        </dd>
                      </div>
                      <div className="mt-3">
                        <dt className="sr-only">Email</dt>
                        <dd className="flex">
                          <EnvelopeIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                          <span className="ml-3">contact<AtSymbolIcon  className="h-5 inline" />fissurometre.fr</span>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>


                <FrmContact  />

                
                
              </div>
            </div>












            



        </main>
        <footer>
          <Footer />
        </footer>
      </div>
    );
  }