// import React from 'react'

// export default function FrmContact() {
//   return (
//         <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
//           <div className="max-w-lg mx-auto lg:max-w-none">
//             <form action="https://app.koudepouce.fr/fissuration/contact/" method="POST" className="grid grid-cols-1 gap-y-6">
//               <div>
//                 <label htmlFor="last-name" className="sr-only">
//                   Nom
//                 </label>
//                 <input
//                   required
//                   type="text"
//                   name="nom"
//                   id="last-name"
//                   className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
//                   placeholder="Nom"
//                 />
//               </div>
//               <div>
//                 <label htmlFor="first-name" className="sr-only">
//                   Prénom
//                 </label>
//                 <input
//                   required
//                   type="text"
//                   name="prenom"
//                   id="first-name"
//                   className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
//                   placeholder="Prénom"
//                 />
//               </div>
//               <div>
//                 <label htmlFor="first-name" className="sr-only">
//                   Code postal
//                 </label>
//                 <input
//                   required
//                   type="text"
//                   name="cp"
//                   id="cp"
//                   className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
//                   placeholder="Code postal"
//                 />
//               </div>
//               <div>
//                 <label htmlFor="email" className="sr-only">
//                   Email
//                 </label>
//                 <input
//                   required
//                   id="email"
//                   name="email"
//                   type="email"
//                   autoComplete="email"
//                   className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
//                   placeholder="Email"
//                 />
//               </div>
//               <div>
//                 <label htmlFor="phone" className="sr-only">
//                   Téléphone
//                 </label>
//                 <input
//                   required
//                   type="text"
//                   name="telephone"
//                   id="phone"
//                   autoComplete="tel"
//                   className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
//                   placeholder="Téléphone"
//                 />
//               </div>
//               <div>
//                 <label htmlFor="message" className="sr-only">
//                   Message
//                 </label>
//                 <textarea
//                   required
//                   id="message"
//                   name="message"
//                   rows={4}
//                   className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border border-gray-300 rounded-md"
//                   placeholder="Message"
//                   defaultValue={''}
//                 />
//               </div>
//               <div>
//                 <label htmlFor="message" className="sr-only">
//                   Message
//                 </label>
//                 <input 
//                     type="checkbox" 
//                     id="cgu" 
//                     name="cgu" 
//                     className="inline mr-3 shadow-sm py-1 px-3 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md" 
//                     required 
//                 /> J'accepte les <a href="/legal/cgu/" className="text-sky-500" target="_blank" rel="noreferrer">Conditions Générales d'Utilisation</a>
//               </div>
//               <div className="hidden">
//                 <label htmlFor="tellepro" className="sr-only">
//                   Téllépro
//                 </label>
//                 <input
//                   type="text"
//                   name="tellepro"
//                   id="tellepro"
//                   className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
//                   placeholder="Téllépro"
//                 />
//               </div>
//               <div>
//                 <button
//                   type="submit"
//                   className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
//                 >
//                   Envoyer
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//   )
// }



import React, {useState, useRef} from 'react'


const Form = (props) => {
  const formRef = useRef(null)
  //const scriptUrl = "https://script.google.com/macros/s/AKfycbzhfbxNPOHtZW9YFvfrYYcHf93gWKYQ-D2QOiFbDKHPv91V-IP4wSMc5lv7YhdjiAw2gw/exec"

  const scriptUrl = "https://script.google.com/macros/s/AKfycbwa7TCAPQGKjEA9o1I4B0zh9bpsr_YcXqgOE-oBqia2Gv4HkuVWhQZHLGS86wDjej4K/exec"

  
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) =>{
      e.preventDefault()
      setLoading(true)

      fetch(scriptUrl, {
      method: 'POST', 
      body: new FormData(formRef.current),

  }).then(res => {
          //console.log("SUCCESSFULLY SUBMITTED")
          window.location.assign('/contact-validation/')
          setLoading(false)
      })
      .catch(err => console.log(err))
  }

  return (
    <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
        <div className="max-w-lg mx-auto lg:max-w-none">
            <form 
                className="gap-y-6 w-full" 
                name="google-sheet"
                ref={formRef} onSubmit={handleSubmit}
            >
                <div className="pb-4">
                    {/* <h3 className="text-2xl font-bold text-center pb-4">
                    Devis Expertise Fissures
                    </h3> */}
                    <label htmlFor="nom" className="sr-only">
                    Nom
                    </label>
                    <input
                        type="text"
                        name="Nom"
                        id="nom"
                        autoComplete="name"
                        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-[#65BCE4] focus:border-[#65BCE4] border-gray-300 rounded-md"
                        placeholder="Nom"
                        required 
                    />
                </div>
                <div className="pb-4">
                    <label htmlFor="nom" className="sr-only">
                    Prénom
                    </label>
                    <input
                    type="text"
                    name="Prénom"
                    id="prenom"
                    autoComplete="name"
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-[#65BCE4] focus:border-[#65BCE4] border-gray-300 rounded-md"
                    placeholder="Prénom"
                    required 
                    />
                </div>
                <div className="pb-4">
                    <label htmlFor="nom" className="sr-only">
                    Code postal
                    </label>
                    <input
                    type="text"
                    name="Code Postal"
                    id="cp"
                    autoComplete="name"
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-[#65BCE4] focus:border-[#65BCE4] border-gray-300 rounded-md"
                    placeholder="Code Postal"
                    required 
                    />
                </div>
                <div className="pb-4">
                    <label htmlFor="telephone" className="sr-only">
                    Téléphone
                    </label>
                    <input
                    type="number"
                    name="Téléphone"
                    id="telephone"
                    autoComplete="tel"
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-[#65BCE4] focus:border-[#65BCE4] border-gray-300 rounded-md"
                    placeholder="Téléphone"
                    required 
                    />
                </div>
                <div className="pb-4">
                    <label htmlFor="email" className="sr-only">
                    Email
                    </label>
                    <input
                    id="email"
                    name="Email"
                    type="email"
                    autoComplete="email"
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-[#65BCE4] focus:border-[#65BCE4] border-gray-300 rounded-md"
                    placeholder="Email"
                    required 
                    />
                </div>
                <div className="pb-4">
                    <label htmlFor="message" className="sr-only">
                    Message
                    </label>
                    <textarea
                    id="message"
                    name="Message"
                    rows={4}
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-[#65BCE4] focus:border-[#65BCE4] border border-gray-300 rounded-md"
                    placeholder="Message"
                    defaultValue={''}
                    required 
                    />
                </div>
                <div className="hidden">
                    <label htmlFor="tellepro" className="sr-only">
                    Téllépro
                    </label>
                    <input
                        type="text"
                        name="tellepro"
                    />
                    <input
                        type="text"
                        name="Source"
                        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                        placeholder="Source"
                        value="Fissurometre.fr"
                    />
                    <input
                        type="text"
                        name="Moyen"
                        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                        placeholder="Moyen"
                        value="Formulaire Fissurometre Contact"
                    />
                    <input
                        type="text"
                        name="Formulaire"
                        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                        placeholder="Formulaire"
                        value="Fissurometre"
                    />
                </div>
                <div className="pb-4 text-left">
                    <input 
                        type="checkbox" 
                        id="cgu" 
                        name="CGU"
                        className="inline mr-2 shadow-sm py-1 px-3 placeholder-gray-500 focus:ring-[#65BCE4] focus:border-[#65BCE4] border-gray-300 rounded-md" 
                        required
                    />J'accepte les <a href="/legal/cgu/" className="text-[#65BCE4]" target="_blank" rel="noreferrer">CGU</a>
                </div>
                <div className="pb-4">
                    <button
                    type="submit"
                    className="inline-flex items-center mr-6 rounded-md ring-1 bg-sky-600 hover:bg-sky-700 px-3.5 py-2.5 text-base font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                    >
                    {loading ? "Envoi en cours ..." : "Envoyer"}
                    </button>
                </div>
            </form>
        </div>
    </div>
  );
}

export default Form